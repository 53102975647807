@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

html {
  letter-spacing: 1px;
  scroll-behavior: smooth;
}

.hide {
  display: none;
}

.active__link {
  color: #ff3008;
}

.accordion {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  transition: 0.4s;
  color: #1f1f1f;
  letter-spacing: 1px;
  transition: transform 0.4s;
}

.panel {
  padding: 18px 0;
  background-color: white;
  display: none;
  overflow: hidden;
  transition: transform 0.4s;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.4s;
}

body {
}
